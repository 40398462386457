body {
  background-color: rgb(70, 69, 69);
  color: lightgrey;
}

.App {
  display: grid;
  grid-template-areas: 
  "header header"
  "sidebar content"
  "footer footer";
  grid-template-columns: 1fr 3fr;
}

a:link {
  color: rgb(255, 217, 0);
}

a:visited {
  color: rgb(160, 136, 0);
}

a:hover {
  text-decoration: none;
}


/* || Navigation header */

header {
  grid-area: header;
}

header ul {
  display: flex;
  list-style: none;
  margin-left: 6em;
  margin-bottom: 2em;
}

header li {
  display: flex;
  align-items: flex-end;
  padding-top: 1.2em;
  padding-bottom: 1.2em;
  padding-left: .1em;
  padding-right: .1em;
}

.logoImg {
  display: flex;
  align-items: center;
  width: 2.7em;
  height: 2.7em;
  padding-right: .5em;
  border-right: 2px solid rgb(255, 217, 0);
  fill: rgb(255, 217, 0);
}

li button {
  background: none;  
  font-size: 2em;
  border: none;
  color: rgb(148, 148, 148);
  cursor: pointer;
}

li button:hover {
  fill: lightgray;
  stroke: lightgray;
}

li:nth-child(n+3) button:active {
  transform: translateY(-2px);
}

.logo button {
  color: lightgray;
}


.settings button {
  position: relative;
  top: -11px;
  width: .9em;
  height: .9em;
  fill: rgb(148, 148, 148);
  stroke: rgb(148, 148, 148);
  stroke-width: .01;
}

.cogno button {
  position: relative;
  top: -10px;
  width: .9em;
  height: .9em;
  fill: rgb(148, 148, 148);
  stroke: rgb(148, 148, 148);
  stroke-width: 10;
}

/* || Memorize page (1) */

.Content {
  height: 75vh;
  width: 80vw;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color:  rgb(70, 69, 69);
}

.overlay-text {
  font-size: 1.5em;
  position: absolute;
  top: 50%;
  left: 44%;
  color: white;
  background-color: rgb(70, 69, 69);
  border-radius: 7px;
  padding: 5px;
  text-shadow: 0px 0px 3px black;
  animation: blink 5s steps(30) infinite;
  z-index: 1;
}

.overlay-background {
  height: 73vh;
  width: 79vw;
  filter: blur(5px);
  user-select: none;
}

.To-memorize {
  flex-grow: 1;
  margin-right: 2em;

}

.To-memorize ul {
  grid-area: content;
  font-size: 1.2em;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1em;
}

.To-memorize ul li {
  display: flex;
  flex-flow: row wrap;
  margin-right: .1em;
  margin-left: .1em;
  margin-top: 2px;
  margin-bottom: 2px;
  padding-left: 2px;
  padding-right: 2px;
  border-radius: 5px;
}

.hidden {
  color: grey;
  background-color: grey;
  user-select: none;
}

.shown {
  color: lightgray;
  background-color: rgb(70, 69, 69);
}

.To-memorize ul div {
  display: flex;
  flex-wrap: nowrap;
}

.hiddenWord {
  margin-right: .1em;
  padding-right: 2px;
  margin-top: 2px;
  margin-bottom: 2px;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  color: grey;
  background-color: black;
  user-select: none;
  
}

.shownWord {
  margin-left: .1em;
  padding-left: 2px;
  margin-top: 2px;
  margin-bottom: 2px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  color: lightgray;
  background-color: grey;
}

/* || Cursor. */

.To-memorize ul #container {
  position: relative;
}

#cursor {
  width: 3px;
  height: 1.2em;
  background: yellow;
  position: absolute;
  top: 2px;
  border-radius: 2px;
  z-index: 1;
  animation: blink 1.25s steps(1) infinite;
}

@keyframes blink {
  50% {
    opacity: 0;
  }  
}

/* || Hud */

#hud {
  color: white;
  background-color: rgb(70, 69, 69);
  border-radius: 7px;
  padding: 5px;
  text-shadow: 0px 0px 3px black;
}

.hudTL {
  position: fixed;
  top: 6em;
}

.hudTR {
  position: fixed;
  top: 6em;
  right: 2em;
}

.hudBL {
  position: fixed;
  bottom: 4em;
}

.hudBR {
  position: fixed;
  bottom: 4em;
  right: 2em;
}

/* || Settings page (2) */

fieldset {
  margin: 1.5em 1.5em 0 0;
  border: none;
}

legend {
  font-size: 1.2em;
  color: rgb(148, 148, 148);
}


fieldset div {
  display: flex;
  flex: 1;
  flex-flow: row wrap;
  align-items: center;
  justify-content: left;
}

.settingsDescriptons {
  flex: 2;
  align-items: left;
  margin-right: 1em;
  margin-left: .5em;
}

.settingsButtons {
  margin-right: .5em;
  height: 3em;
}

.settingsButtons input {
  display: none;
  position: absolute;
}

.buttonContainer label {
  display: flex;
  flex: 1;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-around;
  background-color:rgb(53, 53, 53);
  margin: 2px;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  z-index: 90;
}

.buttonContainer input[type="radio"] {
  opacity: 0.01;
  z-index: 100;
}

.buttonContainer input[type="radio"]:checked+label {
  background: rgb(255, 217, 0);
  color: rgb(70, 69, 69);
}

.buttonContainer label:hover {
  background: rgb(109, 109, 109);
}

/* Score Board Page (3) */

.score-chart {
  display: flex;
  flex-direction: column;
  font-size: 1.5em;
  position: absolute;
  top: 40%;
  left: 43%;
  color: white;
  background-color: rgb(100, 100, 100);
  border-radius: 7px;
  padding: .5em;
  text-shadow: 0px 0px 3px black;
  z-index: 1;
}

#score-buttons {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-around;
}

#score-board {
  display: flex;
  flex: 4;
  flex-direction: row;
  padding: .5em;  
}

.score-names {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  padding: .1em;
}

.score-numbers {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: .1em;
}

.score-names div, .score-numbers div {
  padding: .5em;
}


.Sidebar {
  grid-area: sidebar;
}

.Sidebar ul {
  list-style: none;
}

footer {
  grid-area: footer;
}

footer ul {
  list-style: none;
}